import { Injectable } from '@angular/core'
import { BaseService, OnboardRequest, ProcessResponse } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class ProcessService extends BaseService {

  constructor() {

    super('process')
  }

  onboard(request: OnboardRequest): Observable<ProcessResponse> {
    return this.post('onboard', request)
  }
}
