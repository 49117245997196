import { Page, SearchRequest } from "@mybitzer-workspace/shared"

export interface CompanySearchRequest extends SearchRequest {
  type: string | null;
}

export interface CompanySearchResponse {
  entries: Page<Company>
}

export interface AccountRequestData {
  firstName: string,
  lastName: string,
  email: string,
  userLevel: string,
  roles: string[]
}

export interface CompanyChangeRequest {
  externalId: string;
  name: string;
  type: string;
}

export interface Company {
  id: number,
  externalId: string,
  name: string,
  type: CompanyType
}

export enum CompanyType {
  DEMO = 'DEMO',
  REGULAR = 'REGULAR'
}

export interface Company {
  id: number;
  externalId: string;
  name: string;
  type: CompanyType;
}

export interface CompanyChangeRequest {
  externalId: string;
  name: string;
  type: string;
}

export interface CompanyProfileChangeRequest {
  street: string;
  zip: string;
  city: string;
  country: string;
  state: string;
  phoneNumber: string;
  website: string;
}

export interface CompanyContact {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface CompanyContactChangeRequest {
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface Requester {
  externalId: string;
  name: string;
  email: string;
  azureGuid?: string;
}

