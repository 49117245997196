import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { Feedback, FeedbackInfo } from "./feedback.api"


@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService {

  constructor(private readonly translate: TranslateService) {
    super('feedback')
    this.retryCount = 0
  }

  getAllFeedback(page: number, size: number): Observable<Page<Feedback>> {
    return this.getPaged('', page, size)
  }

  getEntry(id: number): Observable<FeedbackInfo> {
    const params = new HttpParams().append('lang', this.translate.currentLang)

    return this.get(id + '', params)
  }

}
