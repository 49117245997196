import { Injectable } from '@angular/core'
import { ACCOUNTING_ADMIN, ASSET_ADMIN, COMPANY_ADMIN, CUSTOMER_ADMIN, DOCUMENT_ADMIN, FEEDBACK_ADMIN, IMenuService, INGEST_ADMIN, Menu, MenuAction, MenuActionGroup, PRODUCTION_WEEK_IMPORT, SALES_ADMIN, SEARCH_ADMIN, STOCKLEVEL_IMPORT, THING_TEMPLATE_ADMIN } from "@mybitzer-workspace/shared"
import { Observable, of } from "rxjs"


const HEADER_MENU_ITEMS: MenuAction[] = [
  {
    id: 0,
    url: 'search',
    name: 'menu.search',
    icon: 'manage_search',
    rank: 0,
    roles: [SEARCH_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'feedback',
    name: 'menu.feedback',
    icon: 'feedback',
    rank: 0,
    roles: [FEEDBACK_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'sales',
    name: 'menu.sales',
    icon: 'storefront',
    rank: 0,
    roles: [SALES_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'company',
    name: 'menu.company',
    icon: 'domain',
    rank: 0,
    roles: [COMPANY_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'customer',
    name: 'menu.customer',
    icon: 'person',
    rank: 0,
    roles: [CUSTOMER_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'accounting',
    name: 'menu.accounting',
    icon: 'account_balance_wallet',
    rank: 0,
    roles: [ACCOUNTING_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'stock-level',
    name: 'menu.stock-level',
    icon: 'upload_file',
    rank: 0,
    roles: [STOCKLEVEL_IMPORT],
    status: undefined
  },
  {
    id: 0,
    url: 'production-week',
    name: 'menu.production-week',
    icon: 'analytics',
    rank: 0,
    roles: [PRODUCTION_WEEK_IMPORT],
    status: undefined
  },
  {
    id: 0,
    url: 'ingest',
    name: 'menu.ingest',
    icon: 'sync_alt',
    rank: 0,
    roles: [INGEST_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'thing-template',
    name: 'menu.thing-template',
    icon: 'linked_services',
    rank: 0,
    roles: [THING_TEMPLATE_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'document',
    name: 'menu.document',
    icon: 'description',
    rank: 0,
    roles: [DOCUMENT_ADMIN],
    status: undefined
  },
  {
    id: 0,
    url: 'asset',
    name: 'menu.asset',
    icon: 'web_asset',
    rank: 0,
    roles: [ASSET_ADMIN],
    status: undefined
  },
]

const MAIN_MENU_ACTION_GROUP: MenuActionGroup[] = [{
  id: 0,
  name: 'main',
  rank: 0,
  actions: HEADER_MENU_ITEMS
}]

@Injectable({
  providedIn: 'root'
})
export class MenuService implements IMenuService {

  // Hardcode values until BE used
  getMenu(): Observable<Menu> {
    return of({
      id: 1,
      name: 'Menu',
      priority: 1,
      groups: MAIN_MENU_ACTION_GROUP
    })
  }
}
