export interface SearchOperatorInfo {
  id: string
  status: string
  stats: SearchOperatorStats
  handledResources: string[]
}

export interface SearchOperatorStats {
  total: number
  processed: number
  failed: number
}

export enum PollingStatus {
  INITIAL = 'INITIAL',
  CREATE_INDEX = 'CREATE_INDEX',
  LOAD_DATA = 'LOAD_DATA',
  READY = 'READY',
  FAILED = 'FAILED'
}
