import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { IngestProcessInfo } from "./ingest.api"


@Injectable({
  providedIn: 'root'
})
export class IngestService extends BaseService {

  constructor() {
    super('ingest')
    this.retryCount = 0
  }

  importCategory(): Observable<any> {
    return this.post('cms/category', "")
  }

  importSeries(): Observable<any> {
    return this.post('cms/series', "")
  }

  importProduct(): Observable<any> {
    return this.post('cms/product', "")
  }

  importToken(): Observable<any> {
    return this.post('cms/token', "")
  }

  getAllInfo(): Observable<IngestProcessInfo[]> {
    return this.get('process/info')
  }

  load(name: string): Observable<any> {
    return this.post('process/load/' + name, "")
  }
}
