export interface IngestProcessInfo {
  name: string,
  status: IngestProcessStatus,
  stats: IngestProcessStats
}
export enum IngestProcessStatus {
  UNKNOWN = 'UNKNOWN',
  INITIALIZING = 'INITIALIZING',
  INITIALIZED = 'INITIALIZED',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  FAILED = 'FAILED'
}
export interface IngestProcessStats {
  total: number
  received: number
  failed: number
  created: number
  updated: number
  deleted: number
}
