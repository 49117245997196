import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from 'rxjs'
import { Company } from "../company/company.api"
import { B2BUnit, B2BUnitChangeRequest, B2BUnitDetails, SalesArea, SalesAreaChangeRequest } from "./sales.api"

@Injectable({
  providedIn: 'root'
})
export class SalesService extends BaseService {

  constructor() {
    super('sales')
    this.retryCount = 0
  }

  getB2BUnitDetails(page: number, size: number): Observable<Page<B2BUnitDetails>> {
    return this.getPaged('b2bunit/details', page, size)
  }

  createB2BUnit(id: string, request: B2BUnitChangeRequest): Observable<B2BUnit> {
    return this.post('b2bunit/' + id, request)
  }

  assignSalesAreaToB2BUnit(id: string, salesAreaId: number): Observable<SalesArea[]> {
    return this.post(id + '/area/' + salesAreaId, {})
  }

  assignCompanyToB2BUnit(id: string, companyId: number): Observable<Company[]> {
    return this.post(id + '/company/' + companyId, {})
  }

  createSalesArea(request: SalesAreaChangeRequest): Observable<SalesArea> {
    return this.post('area', request)
  }

  getSalesAreas(page: number, size: number): Observable<Page<SalesArea>> {
    return this.getPaged('area', page, size)
  }
}
