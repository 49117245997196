import { Injectable } from '@angular/core'
import { BaseService, Company, CompanyChangeRequest, Page } from "@mybitzer-workspace/shared"
import { Observable } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { CompanySearchRequest, CompanySearchResponse } from './company.api'

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {

  constructor() {
    super('company')
  }

  searchCompany(request: CompanySearchRequest, page: number, size: number): Observable<CompanySearchResponse> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post('search', request, params)
  }

  getAllCompanies(page: number, size: number): Observable<Page<Company>> {
    return this.getPaged('all', page, size)
  }

  getFilteredCompanies(filter: string, page: number, size: number): Observable<Page<Company>> {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged('filtered', page, size, params)
  }

  createCompany(request: CompanyChangeRequest): Observable<Company> {
    return this.post('', request)
  }
}
