import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from "@mybitzer-workspace/shared"
import { Observable } from 'rxjs'
import { SearchOperatorInfo } from "./search.api"


@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {
  query = ''

  constructor() {
    super('search')
  }

  executeSearch(query: string) {
    this.query = query
  }

  setupProcess(processId: string) {
    let params = new HttpParams()
    params = params.append('processId', processId)
    return this.post('process', {}, params)
  }

  getInfo(processId: string): Observable<SearchOperatorInfo> {
    let params = new HttpParams()
    params = params.append('processId', processId)
    return this.get('process', params)
  }

  getAllInfos(): Observable<SearchOperatorInfo[]> {
    return this.get('')
  }
}
