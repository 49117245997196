import { LocaleValue } from "@mybitzer-workspace/shared"

export interface Asset {
  id: string,
  reference: string,
  description: string,
  information: string,
  imageUrl: string,
  links: AssetLink[],
  title: string
}

export interface AssetLink {
  name: string,
  url: string,
}

export class AssetChangeRequest {
  reference: string
  title: { [key: string]: string }
  description: string
  information: string
  imageUrl: string
  links: AssetLink[]

  constructor(
    reference: string,
    title: { [key: string]: string },
    description: string,
    information: string,
    imageUrl: string,
    links: AssetLink[]
  ) {
    this.reference = reference
    this.title = title
    this.description = description
    this.information = information
    this.imageUrl = imageUrl
    this.links = links
  }
}

