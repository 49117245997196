import { Injectable } from '@angular/core'
import { BaseService, Page } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { ThingTemplate } from "./thing-template.api"


@Injectable({
  providedIn: 'root'
})
export class ThingTemplateService extends BaseService {

  constructor() {
    super('template')
    this.retryCount = 0
  }

  getThingTemplates(page: number, size: number): Observable<Page<ThingTemplate>> {
    return this.getPaged('', page, size)
  }

}

