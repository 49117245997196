import { Injectable } from '@angular/core'
import { FileUploadService } from "@mybitzer-workspace/shared"

@Injectable({
  providedIn: 'root'
})
export class ProductionWeekUploadService extends FileUploadService {

  constructor() {
    super('productionweek')
  }
}
