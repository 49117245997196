import { Injectable } from '@angular/core'
import { FileUploadService } from '@mybitzer-workspace/shared'

@Injectable({
  providedIn: 'root'
})
export class StockLevelService extends FileUploadService {

  constructor() {
    super('stocklevel')
  }
}
